<template>
  <div class="card">
    <img
      src="../assets/background-card.webp"
      class="card-img-top"
      alt="Dra. Karina Orta"
    />
    <div class="card-body">
      <h5 class="card-title">Dra. Karina Orta</h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        Ortodoncia y Ortodoncia funcional de los maxilares
      </h6>
    </div>
    <ul class="list-group list-group-flush">
      <li
        class="list-group-item d-flex justify-content-center align-items-center"
        aria-current="true"
      >
        <button
          id="shareBtn"
          style="border: 0px; margin: 2px; background: transparent"
          v-on:click="shareCard"
        >
          <img
            src="../assets/share.svg"
            alt="Logo share"
            width="42"
          />&nbsp;<strong>COMPARTIR</strong>
        </button>
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/whatsapp.svg"
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">WhatsApp</p>
          </div>
        </div>
        <a
          href="https://api.whatsapp.com/send?phone=584249258779&text=¡Hola Dra.karina!"
          class="btn-cta font-weight-bold text-uppercase"
          role="button"
          >Enviar</a
        >
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/call.svg"
            alt=""
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">Teléfono</p>
          </div>
        </div>
        <a
          href="#"
          v-on:click="callMe"
          class="btn-cta font-weight-bold text-uppercase"
          role="button"
          >Llamar</a
        >
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/map.svg"
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">Google Map</p>
          </div>
        </div>
        <a
          href="https://maps.app.goo.gl/NcJiPPEGjJmpNevu8"
          target="_bland"
          class="btn-cta font-weight-bold text-uppercase"
          role="button"
          >Ir</a
        >
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/instagram.svg"
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">Instagram</p>
          </div>
        </div>
        <a
          href="https://www.instagram.com/reel/C2r6OGGOIhY/?utm_source=ig_web_copy_link"
          class="btn-cta font-weight-bold text-uppercase"
          role="button"
          target="_bland"
          >Seguir</a
        >
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/facebook.svg"
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">Facebook</p>
          </div>
        </div>
        <a
          href="https://www.facebook.com/karina.orta.12"
          target="_bland"
          class="btn-cta font-weight-bold text-uppercase"
          role="button"
          >Ver perfil</a
        >
      </li>
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <img
            src="../assets/qr.svg"
            style="width: 45px; height: 45px"
            class="rounded-circle"
          />
          <div class="ms-3">
            <p class="fw-bold mb-1">Guardar en mis contactos</p>
          </div>
        </div>
        <a
          href="#"
          class="btn-cta font-weight-bold text-uppercase"
          data-bs-toggle="modal"
          data-bs-target="#modal-qr"
          role="button"
          >Escanear</a
        >
      </li>
    </ul>
  </div>
  <div
    class="modal fade"
    id="modal-qr"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-qrLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img src="../assets/qr-code.png" alt="qr" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardView",

  data() {
    return {};
  },
  methods: {
    callMe(e) {
      e.preventDefault();
      window.open("tel:+584249258779");
    },
    shareCard(e) {
      e.preventDefault();
      navigator.share({
        title: "Dra. Karina Orta",
        text: "Contáctame",
        url: "https://www.mitarjeta.karinaortaortodoncia.com",
      });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");

body {
  background-color: white;
}

.card .btn-cta {
  border: none;
  border-radius: 3em;
  -webkit-border-radius: 3em;
  -moz-border-radius: 3em;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  padding: 8px 2px;
  background-color: #eceff1;
  width: 130px;
}
</style>
